import React, {useState} from "react";

export function Modal(props){
    const update = () => {
        props.update();
    }

    const stopPropClick = (e)=>{
        e.stopPropagation();
    }
    
    return(
        <div 
        id={props.id} 
        className="modalOut" 
        style={{display : "none"}}
        onClick={update}
        >
            <div className="modalIn" onClick={stopPropClick}>
                {props.content}
            </div>
        </div>
    );
}

