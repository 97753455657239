import React, {useState, useEffect} from "react";
import { Modal } from "./Modal";

/*PROPS ARE: gif, img, id */

export default function ModalButton(props){
    const id = props.id;
    const idImg = props.id + "-img";
    const idGif = props.id + "-gif";

    const idModal = props.id + "-modal";

    const [modalOpen, setOpen] = useState(false);

    useEffect(()=>{
        if(modalOpen==false){
            document.getElementById(idModal).style.display="none";
        }
        else if(modalOpen==true){
            document.getElementById(idModal).style.display="flex";
        }
    }, [modalOpen]);

    const updateModal = () => {setOpen(false)};
    return(
        <div className="footer-top">
            <div id={id}>
                <img
                onClick={()=>{
                    setOpen(true);
                }}
                onMouseEnter={() => {
                    document.getElementById(idGif).style.transform="translateY(-120%)";
                }}
                onMouseLeave={() => {
                    document.getElementById(idGif).style.transform="translateY(0%)";
                }}
                id={idImg}
                src={props.img}
                />
                <img id={idGif} src={props.gif}/>  
            </div>
            <Modal id={idModal} content={props.content} update={updateModal}/>
        </div>
    );
}