import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import { useEffect } from "react";
import FooterHome from "./FooterHome";
import SlideshowHome from "./SlideshowHome";
import MenuMobile from "./MenuMobile";

function Homepage(){

    function unfocusElement(){
        const images=document.getElementsByClassName("img-grey");
        for(let i=0; i<images.length; i++){
             images[i].style.opacity=0;
        } 
    }
    function homeAnimation(element){
        switch(element){
            case "web":
                document.getElementById("web-anim-1").style.transform="translateX(-40vw) translateY(32vh) rotate(-25deg)";
                break;
            case "image":
                document.getElementById("image-anim-1").style.transform="translateY(75vh)";
                break;
            case "identite":
                document.getElementById("identite-anim-1").style.transform="translateX(40vw)";
                document.getElementById("identite-anim-2").style.transform="translateY(45vh)";
                break;
            case "com":
                document.getElementById("com-anim-1").style.transform="translateX(35vw)";
                document.getElementById("com-anim-2").style.transform="translateX(-40vw)";
                break;
            case "edition":
                document.getElementById("edition-anim-1").style.transform="translateX(50vw)";
                document.getElementById("edition-anim-2").style.transform="translateX(-35vw) translateY(-65vh) rotate(-20deg)";
                break;
            case "scolaire":
                document.getElementById("scolaire-anim-1").style.transform="translateX(50vw)";
                document.getElementById("scolaire-anim-2").style.transform="translateX(-45vw) rotate(-20deg)";
                break;
        }
    }
   
    function homeAnimationOut(element){
        switch(element){
            case "web":
                document.getElementById("web-anim-1").style.transform="translateX(0%) translateY(-0%) rotate(0deg)";
                break;
            case "image":
                document.getElementById("image-anim-1").style.transform="translateY(-0%)";
                break;
            case "identite":
                document.getElementById("identite-anim-1").style.transform="translateX(-0%)";
                document.getElementById("identite-anim-2").style.transform="translateY(-0%)";
                break;
            case "com":
                document.getElementById("com-anim-1").style.transform="translateX(-0%)";
                document.getElementById("com-anim-2").style.transform="translateX(0%)";
                break;
            case "edition":
                document.getElementById("edition-anim-1").style.transform="translateX(-0%)";
                document.getElementById("edition-anim-2").style.transform="translateX(0%) translateY(0%) rotate(0deg)";
                break;
            case "scolaire":
                document.getElementById("scolaire-anim-1").style.transform="translateX(-0%)";
                document.getElementById("scolaire-anim-2").style.transform="translateX(0%) rotate(0deg)";
                break;
            
        }
    }
    function focusElement(num){
        const images=document.getElementsByClassName("img-grey");
        for(let i=0; i<images.length; i++){
            if(i==num){}
            else{
                images[i].style.opacity=100;
            }
        }
    }

    return(
        <div id="home-container">
            <MenuMobile />
            <SlideshowHome />
            <img id="web-anim-1" className="anim-home" src="./img/home/home_144pp_web.png"/>

            <img id="image-anim-1" className="anim-home" src="./img/home/home_144pp_image.png"/>

            <img id="identite-anim-1" className="anim-home" src="./img/home/home_144pp_identite1.png"/>
            <img id="identite-anim-2" className="anim-home" src="./img/home/home_144pp_identite2.png"/>

            <img id="com-anim-1" className="anim-home" src="./img/home/home_144pp_com1.png"/>
            <img id="com-anim-2" className="anim-home" src="./img/home/home_144pp_com2.png"/>

            <img id="edition-anim-1" className="anim-home" src="./img/home/home_144pp_edition1.png"/>
            <img id="edition-anim-2" className="anim-home" src="./img/home/home_144pp_edition2.png"/>

            <img id="scolaire-anim-1" className="anim-home" src="./img/home/home_144pp_scolaire1.png"/>
            <img id="scolaire-anim-2" className="anim-home" src="./img/home/home_144pp_scolaire2.png"/>

            <nav id="home-logo-container">
                <li id="home-logo">
                    <Link 
                    onMouseEnter={() => {document.getElementsByClassName("logo-color")[0].style.opacity=100;}}
                    onMouseLeave={() => {document.getElementsByClassName("logo-color")[0].style.opacity=0;}}
                    to="/">
                        <img src="../img/svg/Logo_gris.svg" />
                        <img className="logo-color" src="../img/svg/Logo_couleur.svg" />
                    </Link>
                </li>
            </nav>
            <nav id="home-nav-container">
                <ul id="home-nav">    
                    <li id="home-scolaire">
                        <Link 
                        onMouseEnter={() => {
                            focusElement(0);
                            homeAnimation("scolaire");
                        }}
                        onMouseLeave={() => {
                            unfocusElement();
                            homeAnimationOut("scolaire");
                        }}
                        to="/scolaire">
                            <img className="img-base" src="./img/svg/Item_scolaire.svg"/>
                            <img className="img-grey" src="./img/svg/Item_scolaire_gris.svg"/>
                        </Link>
                    </li>
                    <li id="home-edition">
                        <Link 
                         onMouseEnter={() => {
                             focusElement(1);
                            homeAnimation("edition");
                            }}
                         onMouseLeave={() => {
                             unfocusElement();
                             homeAnimationOut("edition");
                            }}
                        to="/edition">
                            <img className="img-base" src="./img/svg/Item_edition.svg"/>
                            <img className="img-grey" src="./img/svg/Item_edition_gris.svg"/>
                            </Link>
                    </li>
                    <li id="home-com">
                        <Link 
                         onMouseEnter={() => {
                             focusElement(2);
                            homeAnimation("com");
                            }}
                         onMouseLeave={() => {
                             unfocusElement();
                            homeAnimationOut("com");
                            }}
                        to="/communication">
                            <img className="img-base" src="./img/svg/Item_com.svg"/>
                            <img className="img-grey" src="./img/svg/Item_com_gris.svg"/>
                        </Link>
                    </li>
                    <li id="home-identite">
                        <Link 
                         onMouseEnter={() => {
                             focusElement(3);
                             homeAnimation("identite");
                            }}
                         onMouseLeave={() => {
                             unfocusElement();
                             homeAnimationOut("identite");
                            }}
                        to="/identite">
                            <img className="img-base" src="./img/svg/Item_identite.svg"/>
                            <img className="img-grey" src="./img/svg/Item_identite_gris.svg"/>
                        </Link>
                    </li>
                    <li id="home-image">
                        <Link 
                        
                        onMouseEnter={() => {
                            focusElement(4); 
                            homeAnimation("image");
                        }}
                        onMouseLeave={() => {
                            unfocusElement();
                            homeAnimationOut("image");
                            }}to="/image">
                            <img className="img-base" src="./img/svg/Item_image.svg"/>
                            <img className="img-grey" src="./img/svg/Item_image_gris.svg"/>
                        </Link>
                    </li>
                    <li id="home-web">
                        <Link 
                         onMouseEnter={() => {
                             focusElement(5);
                             homeAnimation("web");
                        }}
                         onMouseLeave={() => {
                             unfocusElement();
                             homeAnimationOut("web");
                            }}
                        to="/web">
                            <img className="img-base" src="./img/svg/Item_web.svg"/>
                            <img className="img-grey" src="./img/svg/Item_web_gris.svg"/>
                        </Link>
                    </li>
                </ul>
            </nav>
            <FooterHome />
        </div>
    );
}

export default Homepage;