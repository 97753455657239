import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import FooterGallery from "./FooterGallery";

export default function MenuMobile(props){
    const [menuOpen, setMenuOpen] = useState(false); 
    
  
    

    useEffect(()=>{
        if(menuOpen === true){
            document.getElementById("mobile-menu-bg").style.display="block";
            document.getElementById("mobile-menu").style.transform="translatex(0%)";

        }
        else if(menuOpen === false){
            document.getElementById("mobile-menu-bg").style.display="none";
            document.getElementById("mobile-menu").style.transform="translatex(100%)";
        }
    }, [menuOpen]);

 

    return(
        <div id="mobile-menu-cont">
            <div 
                id="mobile-menu-button"
                onClick={()=>{setMenuOpen(true);}}>
                <img src="./img/svg/Menu_mobile.svg"/>
            </div>
            <div id="mobile-menu-bg" onClick={()=>{setMenuOpen(false);}}></div>
            <div id="mobile-menu">
                <ul id="mobile-menu-list">
                    <li style={{color : "#DBD921"}}>
                        <Link to="/scolaire" onClick={()=>{
                            props.onClick();
                            setMenuOpen(false);}}>
                            Scolaire
                        </Link>
                    </li>
                    <li style={{color : "#B0D64A"}}>
                        <Link to="/edition" onClick={()=>{
                            props.onClick();
                            setMenuOpen(false);}}>
                            édition
                        </Link>
                    </li>
                    <li style={{color : "#82D173"}}>
                        <Link to="/communication" onClick={()=>{
                            props.onClick();
                            setMenuOpen(false);}}>
                            Communication
                        </Link>
                    </li>
                    <li style={{color : "#59CF9E"}} onClick={()=>{
                        props.onClick();
                        setMenuOpen(false);}}>
                        <Link to="/identite">
                            Identité
                        </Link>
                    </li>
                    <li style={{color : "#2BCCC7"}} onClick={()=>{
                         props.onClick();
                        setMenuOpen(false);}}>
                        <Link to="/image">
                            Image
                        </Link>
                    </li>
                    <li style={{color : "#00C7F0"}} onClick={()=>{
                         props.onClick();
                        setMenuOpen(false);}}>
                        <Link to="/web">
                            Web
                        </Link>
                    </li>
                </ul>
                <div id="footer-mobile">
                    <Link to="/a-propos" id="mobile-apropos" onClick={()=>{setMenuOpen(false);}}>
                        <img src="./img/svg/Apropos.svg"/>
                    </Link>
                    <Link to="/contact" id="mobile-contact" onClick={()=>{setMenuOpen(false);}}>
                        <img src="./img/svg/Contact.svg"/>
                    </Link>
                    <Link to="/" id="mobile-logo" onClick={()=>{setMenuOpen(false);}}>
                        <img src="./img/svg/logo_footer.svg"/>
                    </Link>
                </div>
            </div>
        </div>
    );
}