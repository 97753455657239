const slides = [
    {
        "id" : 0,
        "url" : "./img/home/galerie/galeriemobile_1.png"
    },
    {
        "id" : 1,
        "url" : "./img/home/galerie/galeriemobile_2.png"
    }
    ,
    {
        "id" : 2,
        "url" : "./img/home/galerie/galeriemobile_3.png"
    }
    ,
    {
        "id" : 3,
        "url" : "./img/home/galerie/galeriemobile_4.png"
    }
    ,
    {
        "id" : 4,
        "url" : "./img/home/galerie/galeriemobile_5.png"
    },
    {
        "id" : 5,
        "url" : "./img/home/galerie/galeriemobile_6.png"
    }
]

export default slides;