import React, {useState, useEffect} from "react";
import slides from "../js/slidehome";

export default function SlideshowHome(){
    const numSlides = slides.length;
    const slideDiv = (1/numSlides) * 100;

    const trainWidth = (numSlides*100)+"%";
    const styletrain={
        width : trainWidth,
    };
    const[currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        setTimeout(()=>{
                nextSlide();
            }, 2000);
    }, [currentSlide]);

    const nextSlide=()=>{
        if(currentSlide < (numSlides-1)){
            setCurrentSlide(currentSlide +1);
        }
        else{
            setCurrentSlide(0);
        }
    }
    const slidesHomepage = slides.map((slide) => {
        return(
            <div key={slide.id.toString()} className="slide-homepage">
                <img className="img-slide-homepage" src={slide.url} key={slide.id.toString()}></img>
            </div>
        );
    });



    return(
        <div id='slide-home-cont'>
            <div id="slideshow-viewer">
                <div id="slideshow-train" 
                style={{...styletrain, ...{transform : "translateX(-"+(currentSlide*slideDiv)+"%)"}}}>
                    {slidesHomepage}
                </div>
            </div>
        </div>
    );
}