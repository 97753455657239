import { Component } from "react";
import Home from './components/Home';

class App extends Component{
    render(){
        return(
            <Home />
        );
    }
}

export default App;